"use client";

import { useEffect } from "react";
import { usePathname } from "next/navigation";

import { useArticleModalStore } from "../../store/useArticleModalStore";
import { isArticlePage } from "../../utils/page";

const FetchModalState = () => {
  const pathname = usePathname();
  const { setModalReferer, setModalIsOpen, isModalOpen } =
    useArticleModalStore();

  useEffect(() => {
    // Populate modal state from history if available
    if (
      history.state?.isModalOpen !== undefined ||
      history.state?.modalReferer !== undefined
    ) {
      setModalIsOpen(history.state.isModalOpen);
      setModalReferer(history.state.modalReferer);
    }

    if (!isArticlePage(pathname) && isModalOpen) {
      setModalIsOpen(false);
      setModalReferer(undefined);
    }
  }, [pathname]);

  return null;
};

export { FetchModalState };
